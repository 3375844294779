import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  static locationKey = 'location';
  async get(key: string) {
    return (await Preferences.get({ key })).value;
  }

  async set(key: string | 'authToken', value: string) {
    await Preferences.set({ key, value });
  }

  async remove(key: string) {
    await Preferences.remove({ key });
  }
}
